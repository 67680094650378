import * as Button from './../../components/Atoms/Button';
import * as SearchDialog from './../../components/Dialog/SearchDialog';
import * as Titled from './../../components/Atoms/Titled';

import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  PARTS_ALL,
  PC_BASE_BLUEPRINT,
} from '../../constants/PerformanceCheckConstants';
import {
  PAYMENT_METHOD,
  VEHICLE_TYPE_LEVEL1,
  VEHICLE_TYPE_LEVEL3,
  YN,
} from '../../constants/Constants';
import React, { useEffect, useRef, useState } from 'react';

import { BlockUI } from 'primereact/blockui';
import { CommonUtil } from '../../utils/commonUtil';
import CustomerCompanyDialog from '../Customer/CustomerCompanyDialog';
import CustomerDialog from '../Customer/CustomerDialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import FileState from '../../enums/FileState';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';
import { ServiceProvider } from '../../services';
import SubmitType from '../../enums/SubmitType';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import dayjs from 'dayjs';
import performanceCheckUtil from '../../utils/performanceCheckUtil';

const baseValueItems = _.mapValues(PC_BASE_BLUEPRINT, 'valueItems');
const partDefaultValues = _.map(PARTS_ALL, ({ partPoint }) => {
  return {
    [`${partPoint}`]: '',
  };
}).reduce((acc, cur) => {
  return { ...acc, ...cur };
}, {});

const defaultValues = {
  licensePlateNumber: '',
  association: {},
  enterprise: {},
  shop: {},
  inspector: {},
  customerCompany: {},
  customer: {},
  note: '',
  vehicleInformation: {
    vinRemain: '',
    cnmDetail: '',
    madeAt: 'D',
    VHCTY_ASORT_CODE: '1',
    USE_FUEL_CODE: 'a',
    TRVL_DSTNC: 0,
    detailedModelType: 'SA',
    displacement: 0,
    maxPerson: 0,
    LOSS_STATUS: YN.NO,
  },
  vehicleMaintenance: [],
  paymentInformation: {
    ASSRNC_TY_SE_CODE: '2',
    ICNY_CODE: '',
    VHCTY_ASORT_CODE: '1',
    checkPrice: 0,
    checkPriceVAT: 0,
    paidCheckPrice: 0,
    checkPricePaidYN: YN.NO,
    checkPricePaidAt: null,
    checkPricePaidBy: 'TRAN',
    checkPriceItemId: '',
    discountReason: '',
    discountedCheckPrice: 0,
    insurancePrice: 0,
    insurancePriceVAT: 0,
    insurancePricePaidYN: YN.NO,
    insurancePricePaidAt: null,
    insurancePricePaidBy: 'TRAN',
    insurancePolicyNumber: '',
    ewYN: YN.NO,
  },
};

const codeService = ServiceProvider.code;
const performanceCheckService = ServiceProvider.performanceCheck;
const priceService = ServiceProvider.price;

const separateUserData = (userData) => {
  const inspectorSteps = {
    association: _.pick(userData, [
      'associationId',
      'associationCode',
      'associationName',
    ]),
    enterprise: _.pick(userData, [
      'enterpriseId',
      'enterpriseCode',
      'enterpriseName',
    ]),
    shop: _.pick(userData, ['shopId', 'shopCode', 'shopName']),
  };

  return [inspectorSteps, userData];
};

const separateCustomerData = (customerData) => {
  const customerCompany = {
    ..._.pick(customerData, [
      'unionId',
      'complexId',
      'mainPhone',
      'companyName',
      'customerCompanyId',
      'businessRegistrationNumber',
    ]),
  };

  const customer = {
    ..._.pick(customerData, [
      'contact',
      'customerId',
      'customerName',
      'customerCompanyId',
    ]),
  };

  return [customerCompany, customer];
};

const RecheckBaseDataDialog = ({
  userInfo,
  dataId,
  originStatementNumber,
  onHide = null,
}) => {
  const licensePlateNumberRef = useRef(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues, reValidateMode: 'onSubmit' });
  const currentValues = useWatch({ control });
  const originPaymentRef = useRef(null);

  const [codes, setCodes] = useState({});
  const [performanceCheckData, setPerformanceCheckData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [fileItems, setFileItems] = useState([]);
  const [detailedModelTypeOptions, setDetailedModelTypeOptions] = useState(
    _.filter(VEHICLE_TYPE_LEVEL3, { lvl1: 'D', lvl2: '1' })
  );

  const [isDialogOpened, setIsDialogOpened] = useState({
    customerCompany: false,
    customer: false,
    userSearch: false,
    customerSearch: false,
  });

  function setDetailedModelType({
    madeAt = _.get(currentValues, 'vehicleInformation.madeAt'),
    assortType = _.get(currentValues, 'vehicleInformation.VHCTY_ASORT_CODE'),
    // fuelCode = _.get(currentValues, 'vehicleInformation.USE_FUEL_CODE'),
    vehicleInformation = _.get(currentValues, 'vehicleInformation'),
    displace = _.get(currentValues, 'vehicleInformation.displacement'),
    maxPerson = _.get(currentValues, 'vehicleInformation.maxPerson'),
  }) {
    //* filtering 기준
    const filteredOptions = [];
    // 사용할 곳 -> 선택한 보험사의 상품에서 필드값 찾아서 update payment information
    //1. lvl1 : vehicleInformation.madeAt
    //2. lvl2 : vehicleInformation.VHCTY_ASORT_CODE
    const conditions = {
      lvl1: madeAt,
      lvl2: assortType,
    };

    // 2. 전기차나 수소차인 경우에 전기차/수소차 라벨로 책정
    if (
      performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
      performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
    ) {
      conditions.label = '전기차/수소차';

      filteredOptions.push(..._.filter(VEHICLE_TYPE_LEVEL3, conditions));
      setDetailedModelTypeOptions(filteredOptions);

      if (filteredOptions[0]) {
        setValue(
          'vehicleInformation.detailedModelType',
          filteredOptions[0].value
        );
      }
      // return;
      return _.get(filteredOptions, '0.value');
    }

    // 3-4. 이륜, 기타의 경우 보험료를 어떻게 책정해야 하는지?
    if (assortType === '5' || assortType === '9') {
      // ---> 보험 상품 이용이 안되는 거면 자가보증으로 바꾸고 보험사 보증 선택할 수 없도록 보험료 업데이트 되지 않도록 픽스 해버리나?
      // 상세 차종은 해당 없음으로 돌려버리고
      // 점검료는 별도로 선택하도록 함
      const option = {
        lvl1: madeAt,
        lvl2: assortType,
        label: '이륜/기타',
        value: '',
      };
      filteredOptions.push(option);
      setDetailedModelTypeOptions(filteredOptions);

      setValue('vehicleInformation.detailedModelType', option.value);
      // return;
      return _.get(filteredOptions, '0.value');
    }

    // 3-3. 화물/특수의 경우 유저가 직접 선택해야 함
    if (assortType === '3' || assortType === '4') {
      filteredOptions.push(..._.filter(VEHICLE_TYPE_LEVEL3, conditions));

      setDetailedModelTypeOptions(filteredOptions);
      setValue(
        'vehicleInformation.detailedModelType',
        filteredOptions[0].value
      );
      // return;
      return _.get(filteredOptions, '0.value');
    }

    // 필터링 완료 후 보여줄 옵션 업데이트
    // 선택이 가능한 경우 상세 차종 값도 즉시 업데이트
    // 선택이 불가능한 경우 보여줄 값 지정하기

    // 1. 국산 혹은 수입
    // 3. 차종 구분 - 승용, 승합 ... 등등

    // 3-1. 차종이 승용인 경우 배기량 입력에 따라 라벨 자동 책정되도록 함
    // 3-2. 승합인 경우 승차인원 입력에 따라 라벨 자동 책정되도록 함
    if (assortType === '1' || assortType === '2') {
      // vehicle list에서 filter 함수에서 참인 것만 뽑아내야함
      const items =
        _.filter(VEHICLE_TYPE_LEVEL3, {
          ...conditions,
        }) || [];
      const option = _.find(items, (item) => {
        return item.filter(displace, maxPerson, vehicleInformation);
      });
      // filteredOptions.push(option);
      // setDetailedModelTypeOptions(filteredOptions);

      filteredOptions.push(...items);
      setDetailedModelTypeOptions(filteredOptions);

      if (_.get(option, 'value')) {
        setValue(
          'vehicleInformation.detailedModelType',
          _.get(option, 'value')
        );
        return _.get(option, 'value');
      }

      setValue(
        'vehicleInformation.detailedModelType',
        filteredOptions[0].value
      );
      return _.get(filteredOptions, '0.value');
    }
  }

  async function getCustomerCompanyCodes({
    shopId = 0,
    preSelectedCustomerCompanyId,
  }) {
    if (shopId === 0) return [[], 0, {}];

    const { data: customerCompanies } = await codeService.customerCompanies(
      shopId
    );
    const customerCompany =
      (preSelectedCustomerCompanyId
        ? _.find(customerCompanies, { value: preSelectedCustomerCompanyId })
        : _.get(customerCompanies, 0)) || {};
    return [
      customerCompanies,
      _.get(customerCompany, 'value'),
      _.get(customerCompany, 'codeData'),
    ];
  }

  async function getCustomerCodes({
    customerCompanyId = 0,
    preSelectedCustomerId,
  }) {
    if (customerCompanyId === 0) return [[], 0, {}];

    const { data: customers } = await codeService.customers(customerCompanyId);
    const customer =
      (preSelectedCustomerId
        ? _.find(customers, { value: preSelectedCustomerId })
        : _.get(customers, 0)) || {};
    return [customers, _.get(customer, 'value'), _.get(customer, 'codeData')];
  }

  async function getCheckPriceTable({
    associationId = 0,
    enterpriseId = 0,
    shopId = 0,
  } = {}) {
    const { data: checkPriceTable } = await priceService.getCheckPrice({
      associationId,
      enterpriseId,
      shopId,
    });

    console.log(
      '[점검료 조회]',
      associationId,
      enterpriseId,
      shopId,
      checkPriceTable
    );
    return [checkPriceTable];
  }

  async function getInsurancePriceTables({
    associationId = 0,
    enterpriseId = 0,
    shopId = 0,
  } = {}) {
    const { data: insurancePriceTables } =
      await priceService.getInsurancePrices({
        associationId,
        enterpriseId,
        shopId,
      });
    const insurances = _.reduce(
      insurancePriceTables,
      (r, v) => {
        r.push({
          label: `[${_.get(v, 'insuranceCompany')}] ${_.get(v, 'companyName')}`,
          value: _.get(v, 'insuranceCompany'),
        });
        return r;
      },
      []
    );
    const insurance = _.get(insurances, 0) || {};

    console.log(
      '[보험료 조회]',
      associationId,
      enterpriseId,
      shopId,
      insurancePriceTables,
      _.get(insurance, 'value'),
      insurance,
      insurances
    );
    return [
      insurancePriceTables,
      _.get(insurance, 'value'),
      insurance,
      insurances,
    ];
  }

  const getDetailData = (whole, baseSubmitData) => {
    const detail = {};

    const baseKeys = new Set(Object.keys(baseSubmitData));
    const expectionKeys = new Set([
      'vinRemain',
      'cnmDetail',
      'madeAt',
      'detailedModelType',
      'displacement',
      'maxPerson',
      'STMD_AT',
      'GRBX_KND_NM',
      'MTRS_FOM_NM',
      'USE_FUEL_NM',
      'INSPT_VALID_PD',
      'PRPOS_CHANGE_SE_AT',
      'LATEST_TRVL_DSTNC',
      'CNTC_RESULT_CODE',
      'CNTC_RESULT_DTLS',
      'RCEPT_GRC_CODE',
      'RCEPT_DE',
      'RCEPT_SN',
      'RCEPT_UPD_SN',
      'VHMNO',
    ]);
    const convertType = new Set([
      'CRMN_MESURE_VALUE',
      'HYDR_MESURE_VALUE',
      'SMOKE_MESURE_VALUE',
      'PRYE',
      'TRVL_DSTNC',
    ]);

    for (const [field, value] of Object.entries(whole)) {
      if (!baseKeys.has(field) && !expectionKeys.has(field)) {
        detail[field] = convertType.has(field) ? String(value) : value;
      }
    }

    return detail;
  };

  async function onSubmitNote(dataId, statementNumber, note) {
    try {
      const { data } = await performanceCheckService.updateNote({
        dataId: Number(dataId),
        statementNumber,
        note,
      });

      const {
        performanceCheckData: { note: updatedNote },
      } = data;

      setValue('note', updatedNote);
    } catch (error) {
      console.log(error);
    }
  }

  const onSubmitDetail = async (submitType = '', formData, dataId) => {
    const performanceStatus =
      performanceCheckUtil.PerformanceStatus.convertForServer(formData);
    const partStatus =
      performanceCheckUtil.PartStatus.convertForServer(formData);
    const { fileInfo, files } =
      performanceCheckUtil.File.convertForServer(fileItems);

    const requestData = {
      dataId,
      submitType,
      checkData: {
        ...formData,
        performanceStatus,
        partStatus,
        CHCK_DE: dayjs().format('YYYYMMDD'),
      },
      fileData: fileInfo,
    };

    setLoading(true);

    try {
      const { data } = await performanceCheckService.registerDetail(
        requestData,
        files
      );
      return { data };
    } catch (error) {
      console.log(error);
      return { error };
    }
  };

  async function onSubmit(submitType = '', formData) {
    console.log('[RECHECK SUBMIT]', formData);

    const baseSubmitData = {
      licensePlateNumber: _.get(formData, 'licensePlateNumber'),
      association: _.get(formData, 'association'),
      enterprise: _.get(formData, 'enterprise'),
      shop: _.get(formData, 'shop'),
      inspector: _.get(formData, 'inspector'),
      customerCompany: _.get(formData, 'customerCompany'),
      customer: _.get(formData, 'customer'),
      vehicleMaintenance: _.get(formData, 'vehicleMaintenance'),
      vehicleInformation: {
        ..._.get(formData, 'vehicleInformation'),
        maxPerson: Number(_.get(currentValues, 'vehicleInformation.maxPerson')),
      },
      paymentInformation: _.get(formData, 'paymentInformation'),
    };

    try {
      const {
        data: {
          performanceCheckData: {
            dataId: newDataId,
            statementNumber: newStatementNumber,
          },
        },
      } = await performanceCheckService.registerRecheckBase({
        submitType,
        dataId,
        ...baseSubmitData,
      });

      const { data: detailRes } = await onSubmitDetail(
        submitType,
        getDetailData(
          {
            ...formData,
            ICNY_CODE: _.get(formData, 'paymentInformation.ICNY_CODE'),
          },
          baseSubmitData
        ),
        newDataId
      );

      // 노트 업데이트
      if (_.get(currentValues, 'note') !== '') {
        // console.log('노트 데이터', _.get(currentValues, 'note'));
        await onSubmitNote(
          newDataId,
          newStatementNumber,
          _.get(currentValues, 'note')
        );
      }

      if (detailRes) {
        setTimeout(() => {
          CommonUtil.Window.popup(
            `/performance-check/form/${newStatementNumber}-${newDataId}`
          );
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error(
        '재성능점검 기본정보 등록 실패',
        `[${error?.code}] ${error?.message}`
      );
    }
  }

  function getCarTypeCode() {
    return `${_.get(currentValues, 'vehicleInformation.madeAt')}${_.get(
      currentValues,
      'vehicleInformation.VHCTY_ASORT_CODE'
    )}${_.get(currentValues, 'vehicleInformation.detailedModelType')}`;
  }

  function clearCheckPrice() {
    setValue('paymentInformation.checkPriceOf', '');
    setValue('paymentInformation.checkPriceTableId', 0);
    setValue('paymentInformation.checkPriceItemId', '');
    setValue('paymentInformation.checkPrice', 0);
    setValue('paymentInformation.checkPriceVAT', 0);
  }

  function setCheckPrice({
    checkPriceTable = codes.checkPriceTable,
    selectedItemId = _.get(
      currentValues,
      'paymentInformation.checkPriceItemId'
    ),
    discountedCheckPrice = _.get(
      currentValues,
      'paymentInformation.discountedCheckPrice'
    ),
  } = {}) {
    const { checkPriceOf, checkPriceTableId, checkPriceItems } =
      checkPriceTable || {};

    if (checkPriceItems) {
      const itemId = selectedItemId || _.get(checkPriceItems, '0.id');
      const item = _.find(checkPriceItems, { id: itemId });

      if (!_.get(currentValues, 'paymentInformation.checkPriceItemId')) {
        setValue('paymentInformation.checkPriceItemId', itemId);
      }

      const totalCheckPrice = _.toNumber(_.get(item, 'price'));
      const checkPriceVAT = Math.floor(totalCheckPrice / 11 / 10) * 10;
      const checkPrice = totalCheckPrice - checkPriceVAT;

      setValue('paymentInformation.checkPriceOf', checkPriceOf);
      setValue('paymentInformation.checkPriceTableId', checkPriceTableId);

      setValue(
        'paymentInformation.checkPrice',
        _.isNaN(checkPrice) ? 0 : checkPrice
      );
      setValue(
        'paymentInformation.checkPriceVAT',
        _.isNaN(checkPriceVAT) ? 0 : checkPriceVAT
      );

      if (
        _.isNaN(totalCheckPrice) ||
        totalCheckPrice - discountedCheckPrice <= 0
      ) {
        console.log('checkprice settlement N');
        const current = dayjs().format('YYYY-MM-DDTHH:mm:ss');
        setValue('paymentInformation.checkPricePaidAt', current);
        setValue('paymentInformation.checkPricePaidYN', YN.YES);
      } else {
        setValue('paymentInformation.checkPricePaidAt', null);
        setValue('paymentInformation.checkPricePaidYN', YN.NO);
      }

      return [totalCheckPrice, checkPriceVAT, checkPrice];
    }

    clearCheckPrice();
    return [0, 0, 0];
  }

  function clearInsurancePrice() {
    setValue('paymentInformation.ASSRNC_TY_SE_CODE', '1');
    setValue('paymentInformation.ICNY_CODE', '');
    setValue('paymentInformation.insurancePriceOf', '');
    setValue('paymentInformation.insurancePriceTableId', 0);
    setValue('paymentInformation.insurancePrice', 0);
    setValue('paymentInformation.insurancePriceVAT', 0);
  }

  function setInsurancePrice({
    distance = _.get(currentValues, 'TRVL_DSTNC'),
    warrantyType = _.get(currentValues, 'paymentInformation.ASSRNC_TY_SE_CODE'),
    assortType = _.get(currentValues, 'vehicleInformation.VHCTY_ASORT_CODE'),
    madeAt = _.get(currentValues, 'vehicleInformation.madeAt'),
    detailModel = _.get(currentValues, 'vehicleInformation.detailedModelType'),
    insurancePriceTables = _.get(codes, 'insurancePriceTables'),
  } = {}) {
    const carTypeCode = getCarTypeCode({ madeAt, assortType, detailModel });
    const suffix = performanceCheckUtil.Distance.getInsuranceSuffix(distance);

    if (suffix === 'X') {
      console.log('[보증 대상 아님]');
      clearInsurancePrice();
      // total,
      return [0, 0, 0];
    } else if (assortType === '5' || assortType === '9') {
      // 이륜이나 기타일때
      const current = dayjs().format('YYYY-MM-DDTHH:mm:ss');

      setValue('paymentInformation.ICNY_CODE', '');
      setValue('paymentInformation.ASSRNC_TY_SE_CODE', '1');
      setValue('paymentInformation.insurancePricePaidAt', current);
      setValue('paymentInformation.insurancePricePaidYN', 'Y');

      console.log('[이륜 혹은 기타]');

      return [0, 0, 0];
    } else {
      const insuranceCompany =
        // _.get(currentValues, 'paymentInformation.ICNY_CODE') ||
        _.get(_.get(insurancePriceTables, '0'), 'insuranceCompany');
      const currentPriceTable = _.find(insurancePriceTables, {
        insuranceCompany,
      });

      if (currentPriceTable) {
        const { priceTableId, priceTable, insurancePriceOf } =
          currentPriceTable;

        const previousInsurancePrice =
          _.get(originPaymentRef.current, 'insurancePrice') || 0;
        const previousInsurancePriceVAT =
          _.get(originPaymentRef.current, 'insurancePriceVAT') || 0;
        const previousTotalInsurancePrice =
          _.get(originPaymentRef.current, 'totalInsurancePrice') || 0;

        const currentInsurancePrice =
          _.toNumber(_.get(priceTable, `${carTypeCode}_${suffix}`)) || 0;
        const currentInsurancePriceVAT =
          Math.floor((currentInsurancePrice * 0.1) / 10) * 10;
        const currentTotalInsurancePrice =
          currentInsurancePrice + currentInsurancePriceVAT;

        const isMoreExpensive =
          currentTotalInsurancePrice - previousTotalInsurancePrice > 0;

        const insurancePrice = isMoreExpensive
          ? currentInsurancePrice - previousInsurancePrice
          : 0;
        const insurancePriceVAT = isMoreExpensive
          ? currentInsurancePriceVAT - previousInsurancePriceVAT
          : 0;
        const totalInsurancePrice = isMoreExpensive
          ? currentTotalInsurancePrice - previousTotalInsurancePrice
          : 0;

        setValue('paymentInformation.ICNY_CODE', insuranceCompany);
        setValue('paymentInformation.insurancePriceOf', insurancePriceOf);
        setValue('paymentInformation.insurancePriceTableId', priceTableId || 0);
        setValue(
          'paymentInformation.insurancePrice',
          _.isNaN(insurancePrice) ? 0 : insurancePrice
        );
        setValue(
          'paymentInformation.insurancePriceVAT',
          _.isNaN(insurancePriceVAT) ? 0 : insurancePriceVAT
        );

        if (
          _.isNaN(totalInsurancePrice) ||
          totalInsurancePrice === 0 ||
          !isMoreExpensive
        ) {
          const current = dayjs().format('YYYY-MM-DDTHH:mm:ss');
          setValue('paymentInformation.insurancePricePaidAt', current);
          setValue('paymentInformation.insurancePricePaidYN', YN.YES);
        } else {
          console.log('settlement Y');
          setValue('paymentInformation.insurancePricePaidAt', null);
          setValue('paymentInformation.insurancePricePaidYN', YN.NO);
        }

        return [totalInsurancePrice, insurancePriceVAT, insurancePrice];
      } else {
        clearInsurancePrice();
        return [0, 0, 0];
      }
    }
  }

  function calculatePrices({
    checkPrice = _.toNumber(
      _.get(currentValues, 'paymentInformation.checkPrice')
    ),
    checkPriceVAT = _.toNumber(
      _.get(currentValues, 'paymentInformation.checkPriceVAT')
    ),
    insurancePrice = _.toNumber(
      _.get(currentValues, 'paymentInformation.insurancePrice')
    ),
    insurancePriceVAT = _.toNumber(
      _.get(currentValues, 'paymentInformation.insurancePriceVAT')
    ),
    discountedCheckPrice = _.toNumber(
      _.get(currentValues, 'paymentInformation.discountedCheckPrice')
    ),
  } = {}) {
    const discountedCheckPriceVAT =
      Math.floor(discountedCheckPrice / 11 / 10) * 10 || 0;
    // VAT 별도 금액
    const discountCheckPrice =
      discountedCheckPrice - discountedCheckPriceVAT || 0;

    const summaryPrice = checkPrice + insurancePrice - discountCheckPrice;
    const vat = checkPriceVAT + insurancePriceVAT - discountedCheckPriceVAT;
    const totalPrice = summaryPrice + vat;

    setValue(
      'paymentInformation.summaryPrice',
      _.isNaN(summaryPrice) ? 0 : summaryPrice
    );
    setValue('paymentInformation.vat', _.isNaN(vat) ? 0 : vat);
    setValue(
      'paymentInformation.totalPrice',
      _.isNaN(totalPrice) ? 0 : totalPrice
    );

    return [totalPrice, summaryPrice, vat];
  }

  const getDistance = (distance) => {
    if (distance === '') return 0;
    return distance;
  };

  function isCheckPricePaid() {
    return (
      _.get(currentValues, 'paymentInformation.checkPricePaidYN') === YN.YES
    );
  }

  function isInsurancePricePaid() {
    return (
      _.get(currentValues, 'paymentInformation.insurancePricePaidYN') === YN.YES
    );
  }

  function getDistanceCode(distance, releaseYear) {
    // 연수 구하기 : 현재 년도 - 출시 년도
    const years = new Date().getFullYear() - releaseYear;

    // 기준값은 연당 20000
    const standard = years * 20000;

    // 오차 범위 기준
    const gap = 10000;

    const differ = standard - distance;

    // 적음 : 0 < standard - distance < 10000
    if (differ > gap) {
      return '1';
    }

    // 보통 : 10000 <= standard - distance <= 0
    if (differ >= 0 && differ <= gap) {
      return '2';
    }

    // 많음 : standard - distance < 0
    if (differ < 0) {
      return '3';
    }
  }

  //ANCHOR - 기존성능점검 데이터
  useEffect(() => {
    (async () => {
      setLoading(true);

      console.group(`[기존성능] ${originStatementNumber}`);
      const { data, error } = await performanceCheckService.getData(dataId);

      if (data) {
        const { performanceCheckData, performanceCheckPhotos } = data;

        const {
          dataId,
          statementNumber,
          licensePlateNumber,
          VHRNO,
          association: associationRaw,
          enterprise: enterpriseRaw,
          shop: shopRaw,
          inspector: inspectorRaw,
          customerCompany: customerCompanyRaw,
          customer: customerRaw,
          vehicleInformation: vehicleInformationRaw,
          vehicleMaintenance: vehicleMaintenanceRaw,
          paymentInformation: paymentInformationRaw,
          checkData: checkDataRaw,
          fileData: fileDataRaw,
          note,
        } = performanceCheckData;

        const association = JSON.parse(associationRaw);
        const enterprise = JSON.parse(enterpriseRaw);
        const shop = JSON.parse(shopRaw);
        const inspector = JSON.parse(inspectorRaw);
        const customerCompany = JSON.parse(customerCompanyRaw);
        const customer = JSON.parse(customerRaw);
        const vehicleInformation = JSON.parse(vehicleInformationRaw);
        const vehicleMaintenance = JSON.parse(vehicleMaintenanceRaw);
        const paymentInformation = JSON.parse(paymentInformationRaw);
        const checkData = JSON.parse(checkDataRaw);
        const fileData = JSON.parse(fileDataRaw);

        originPaymentRef.current = paymentInformation;

        const options = _.filter(VEHICLE_TYPE_LEVEL3, {
          lvl1: _.get(vehicleInformation, 'madeAt'),
          lvl2: _.get(vehicleInformation, 'VHCTY_ASORT_CODE'),
        });
        setDetailedModelTypeOptions(options);

        const { associationId, enterpriseId, shopId } = inspector;
        const {
          checkPriceOf,
          checkPriceTableId,
          insurancePriceOf,
          insurancePriceTableId,
        } = paymentInformation;

        if (associationId && enterpriseId && shopId) {
          const [
            [checkPriceTable],
            [insurancePriceTables, insuranceCompany, , insurances],
          ] = await Promise.all([
            getCheckPriceTable({
              associationId,
              enterpriseId,
              shopId,
              checkPriceOf,
              checkPriceTableId: checkPriceTableId || 0,
            }),
            getInsurancePriceTables({
              associationId,
              enterpriseId,
              shopId,
              insurancePriceOf,
              insurancePriceTableId: insurancePriceTableId || 0,
            }),
          ]);

          setCodes((ps) => ({
            ...ps,
            insurancePriceTables,
            insurances,
            checkPriceTable,
          }));

          if (performanceCheckPhotos.length > 0) {
            const loadedPhotos = performanceCheckPhotos.map((item) => {
              item.state = FileState.Loaded;
              return item;
            });
            setFileItems(loadedPhotos);
          }

          setPerformanceCheckData({
            ...defaultValues,
            ...partDefaultValues,
            ...checkData,
            dataId: null,
            statementNumber,
            originDataId: dataId,
            originStatementNumber,
            licensePlateNumber,
            association,
            enterprise,
            shop,
            inspector,
            customerCompany,
            customer,
            vehicleInformation: {
              ...vehicleInformation,
              detailedModelType:
                _.get(vehicleInformation, 'detailedModelType') ||
                setDetailedModelType({
                  madeAt: _.get(vehicleInformation, 'madeAt'),
                  assortType: _.get(vehicleInformation, 'VHCTY_ASORT_CODE'),
                  vehicleInformation: vehicleInformation,
                  displace: _.get(vehicleInformation, 'displacement'),
                  maxPerson: _.get(vehicleInformation, 'maxPerson'),
                }),
              VHRNO: _.get(vehicleInformation, 'VHRNO') || VHRNO,
              LOSS_STATUS: _.get(vehicleInformation, 'LOSS_STATUS') || YN.NO,
              MTRS_FOM:
                _.get(vehicleInformation, 'MTRS_FOM') ||
                _.get(vehicleInformation, 'MTRS_FOM_NM'),
              TRVL_DSTNC_STTUS_CODE: getDistanceCode(
                getDistance(vehicleInformation['TRVL_DSTNC']),
                _.get(vehicleInformation, 'PRYE')
              ),
            },
            vehicleMaintenance,
            paymentInformation: {
              ...paymentInformation,
              ICNY_CODE:
                paymentInformation.ASSRNC_TY_SE_CODE === '1'
                  ? ''
                  : insuranceCompany,
              checkPriceItemId: '',
              checkPrice: 0,
              checkPriceVAT: 0,
              insurancePrice: 0,
              insurancePriceVAT: 0,
              discountCheckPrice: 0,
              checkPricePaidYN: YN.NO,
              checkPricePaidAt: null,
              insurancePricePaidYN: YN.NO,
              insurancePricePaidAt: null,
              checkPricePaidBy: 'TRAN',
              insurancePricePaidBy: 'TRAN',
            },
            fileData,
            ...vehicleInformation,
            note: note,
          });
        }
      }

      if (error) {
        window.cerp.dialog.error(
          '성능점검 기본정보 조회 실패',
          `[${error?.code}] ${error?.message}`
        );
      }

      console.groupEnd();

      setLoading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originStatementNumber]);

  useEffect(() => {
    if (performanceCheckData !== null) {
      reset(performanceCheckData);
    }
  }, [performanceCheckData, reset]);

  return (
    <Dialog
      modal
      visible={true}
      maximizable
      breakpoints={{ '960px': '90vw' }}
      header={`재성능점검 등록`}
      style={{ width: '76vw' }}
      contentClassName="cm-dialog-content"
      onHide={onHide}
      footer={
        <div className="flex flex-auto align-items-start justify-content-start pt-4">
          <div className="flex flex-auto flex-wrap align-items-center justify-content-start gap-1">
            <Button.Default
              type="button"
              label="닫기"
              className="p-button-outlined"
              onClick={onHide}
            />
          </div>
          <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
            <Button.Default
              type="button"
              label={'재성능점검 시작'}
              icon={'pi pi-car'}
              disabled={_.isEmpty(
                _.get(currentValues, 'vehicleInformation.CNM')
              )}
              onClick={() => {
                handleSubmit((formData) =>
                  onSubmit(SubmitType.Temp, formData)
                )();

                onHide();
              }}
            />
          </div>
        </div>
      }
    >
      <BlockUI
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <form>
          <div className="grid">
            <div className="col-12 mb-2 py-0">
              <Panel header="0. 성능점검 정보">
                <table
                  className="w-full h-full border-1 border-gray-100 border-round-sm"
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <thead />
                  <tbody>
                    <tr className="border-1 border-gray-100">
                      <th className="px-2 py-1 border-gray-100 border-bottom-1 bg-gray-100">
                        성능지 번호
                      </th>
                      <td className="px-2 border-gray-100 border-bottom-1">
                        {_.get(currentValues, 'statementNumber')}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
            </div>

            <div className="col-12 lg:col-6 lg:mb-0 py-0 mb-2">
              <Panel header="1. 성능점검자 정보">
                <div className="grid mb-0">
                  <div className="col-12 sm:col-8">
                    {_.isEmpty(_.get(currentValues, 'inspector')) ? (
                      <span>1. 성능점검자를 선택하세요.</span>
                    ) : (
                      <table
                        className="w-full h-full border-1 border-gray-100 border-round-sm"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <thead />
                        <tbody>
                          <tr className="border-1 border-gray-100">
                            <th className="px-2 py-1 border-gray-100 border-bottom-1 bg-gray-100">
                              협회
                            </th>
                            <td className="px-2 border-gray-100 border-bottom-1">
                              [
                              {_.get(
                                _.get(currentValues, 'inspector'),
                                'associationCode'
                              )}
                              ]{' '}
                              {_.get(
                                _.get(currentValues, 'inspector'),
                                'associationName'
                              )}
                            </td>
                          </tr>
                          <tr className="border-1 border-gray-100">
                            <th className="px-2 py-1 border-gray-100 border-bottom-1 bg-gray-100">
                              점검업체
                            </th>
                            <td className="px-2 border-gray-100 border-bottom-1">
                              [
                              {_.get(
                                _.get(currentValues, 'inspector'),
                                'enterpriseCode'
                              )}
                              ]{' '}
                              {_.get(
                                _.get(currentValues, 'inspector'),
                                'enterpriseName'
                              )}
                            </td>
                          </tr>
                          <tr className="border-1 border-gray-100">
                            <th className="px-2 py-1 border-gray-100 border-bottom-1 bg-gray-100">
                              점검장
                            </th>
                            <td className="px-2 border-gray-100 border-bottom-1">
                              [
                              {_.get(
                                _.get(currentValues, 'inspector'),
                                'shopCode'
                              )}
                              ]{' '}
                              {_.get(
                                _.get(currentValues, 'inspector'),
                                'shopName'
                              )}
                            </td>
                          </tr>
                          <tr className="border-1 border-gray-100">
                            <th className="px-2 py-1 border-gray-100 border-bottom-1 bg-gray-100">
                              점검자
                            </th>
                            <td className="px-2">
                              {_.get(
                                _.get(currentValues, 'inspector'),
                                'userName'
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </Panel>
            </div>

            <div className="col-12 lg:col-6 lg:mb-0 py-0">
              <Panel header="2. 고객 정보">
                <div className="grid mb-0">
                  <div className="col-12 sm:col-8">
                    {_.isEmpty(_.get(currentValues, 'customer')) ? (
                      <span>2. 고객을 선택하세요.</span>
                    ) : (
                      <table
                        className="w-full h-full border-1 border-gray-100 border-round-sm"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <thead />
                        <tbody>
                          <tr className="border-1 border-gray-100">
                            <th className="bg-gray-100 px-2 py-1 border-gray-100 border-bottom-1">
                              상사정보
                            </th>
                            <td className="px-2 border-gray-100 border-bottom-1">
                              {_.get(
                                currentValues,
                                'customerCompany.companyName'
                              )}{' '}
                              (
                              {_.get(
                                currentValues,
                                'customerCompany.businessRegistrationNumber'
                              )}
                              )
                            </td>
                          </tr>
                          <tr className="border-1 border-gray-100">
                            <th className="bg-gray-100 px-2 py-1">고객정보</th>
                            <td className="px-2">
                              {_.get(currentValues, 'customer.customerName')} |{' '}
                              {_.get(currentValues, 'customer.contact')}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </Panel>
            </div>
          </div>

          <div className="grid mt-2">
            <div className="col-12 mb-2 lg:col-6 lg:mb-0 py-0">
              <Panel header="3. 차량 정보">
                <div className="grid mb-0">
                  <div className="col-12">
                    <div className="field">
                      <label>차량번호</label>
                      <Controller
                        control={control}
                        name="licensePlateNumber"
                        defaultValue=""
                        render={({ field, fieldState, formState }) => (
                          <div className="p-inputgroup">
                            <InputText
                              ref={(el) => {
                                licensePlateNumberRef.current = el;
                              }}
                              id={field.name}
                              placeholder="차량번호"
                              {...field}
                              readOnly
                              className={classNames('', {
                                'bg-gray-100': true,
                              })}
                              onFocus={(e) => e.target.select()}
                            />
                          </div>
                        )}
                      />
                    </div>

                    <BlockUI
                      blocked={_.isEmpty(
                        _.get(currentValues, 'vehicleInformation.CNM')
                      )}
                    >
                      <Panel header="차량상세정보" className="cm-inner-panel">
                        <div className="grid">
                          <div className="col-12">
                            <Controller
                              control={control}
                              name="vehicleInformation.CNM"
                              defaultValue=""
                              render={({ field, fieldState, formState }) => (
                                <Titled.InputText
                                  title="차명"
                                  {...field}
                                  readOnly
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <Controller
                              control={control}
                              name="vehicleInformation.cnmDetail"
                              defaultValue=""
                              render={({ field, fieldState, formState }) => (
                                <Titled.InputText
                                  title="세부모델"
                                  {...field}
                                  disabled
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <Controller
                              control={control}
                              name="vehicleInformation.PRYE"
                              defaultValue=""
                              render={({ field, fieldState, formState }) => (
                                <Titled.InputNumber
                                  title="차량연식"
                                  {...field}
                                  readOnly
                                  useGrouping={false}
                                  suffix="년식"
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <div className="field m-0">
                              <label>주행거리</label>
                              <Controller
                                control={control}
                                name="vehicleInformation.TRVL_DSTNC"
                                defaultValue={0}
                                // rules={{
                                //   required: '필수 입력항목입니다.',
                                //   validate: (v) =>
                                //     v >= 0 || '0보다 커야합니다.',
                                // }}
                                render={({ field, fieldState, formState }) => (
                                  <InputNumber
                                    id={field.name}
                                    {..._.omit(field, ['onChange'])}
                                    inputRef={field.ref}
                                    className="w-full"
                                    inputClassName="w-full text-right"
                                    onChange={(e) => {
                                      const value =
                                        CommonUtil.Formatter.trvlDistance(
                                          e.value
                                        );

                                      field.onChange(value);
                                      if (
                                        performanceCheckUtil.Distance.getInsuranceSuffix(
                                          value
                                        ) === 'X'
                                      ) {
                                        setValue(
                                          'paymentInformation.ASSRNC_TY_SE_CODE',
                                          '1'
                                        );
                                      } else {
                                        setValue(
                                          'paymentInformation.ASSRNC_TY_SE_CODE',
                                          '2'
                                        );
                                      }
                                      setValue(
                                        'TRVL_DSTNC_STTUS_CODE',
                                        getDistanceCode(
                                          value,
                                          currentValues['PRYE']
                                        )
                                      );
                                      // setCheckPrice();
                                      const [
                                        ,
                                        insurancePriceVAT,
                                        insurancePrice,
                                      ] = setInsurancePrice({
                                        distance: value,
                                      });
                                      calculatePrices({
                                        insurancePriceVAT,
                                        insurancePrice,
                                      });
                                    }}
                                    suffix=" Km"
                                    inputmode="numeric"
                                    onFocus={(e) => e.target.select()}
                                    disabled
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <div className="field m-0">
                              <label>
                                <i className="text-red-400 pi pi-check mr-1" />
                                차대번호
                              </label>
                              <div className="p-inputgroup">
                                <div className="p-inputgroup-addon">
                                  {_.get(
                                    currentValues,
                                    'vehicleInformation.VIN'
                                  )}
                                </div>
                                <Controller
                                  control={control}
                                  name="vehicleInformation.vinRemain"
                                  defaultValue=""
                                  render={({
                                    field,
                                    fieldState,
                                    formState,
                                  }) => (
                                    <InputText
                                      {...field}
                                      placeholder="6자리"
                                      minLength="6"
                                      maxLength="6"
                                      disabled
                                      onFocus={(e) => e.target.select()}
                                      className={classNames('', {
                                        'p-invalid': _.get(
                                          errors,
                                          'vehicleInformation.vinRemain'
                                        )?.message,
                                      })}
                                    />
                                  )}
                                />
                              </div>
                              {_.get(
                                errors,
                                'vehicleInformation.vinRemain'
                              ) && (
                                <small className="p-error">
                                  {
                                    _.get(
                                      errors,
                                      'vehicleInformation.vinRemain'
                                    )?.message
                                  }
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <div className="field m-0">
                              <label>최초등록일</label>
                              <div className="p-inputgroup">
                                <Controller
                                  control={control}
                                  name="vehicleInformation.FRST_REGIST_DE"
                                  defaultValue=""
                                  render={({
                                    field,
                                    fieldState,
                                    formState,
                                  }) => (
                                    <InputMask
                                      {...field}
                                      className="bg-gray-100"
                                      mask="9999-99-99"
                                      slotChar="yyyy-mm-dd"
                                      readOnly
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <div className="field m-0">
                              <label>검사유효기간</label>
                              <div className="p-inputgroup">
                                <Controller
                                  control={control}
                                  name="vehicleInformation.INSPT_VALID_PD_BGNDE"
                                  defaultValue=""
                                  render={({
                                    field,
                                    fieldState,
                                    formState,
                                  }) => (
                                    <InputMask
                                      {...field}
                                      mask="9999-99-99"
                                      slotChar="yyyy-mm-dd"
                                      disabled
                                      onFocus={(e) => e.target.select()}
                                    />
                                  )}
                                />
                                <span className="p-inputgroup-addon w-min">
                                  {' '}
                                  ~{' '}
                                </span>
                                <Controller
                                  control={control}
                                  name="vehicleInformation.INSPT_VALID_PD_ENDDE"
                                  defaultValue=""
                                  render={({
                                    field,
                                    fieldState,
                                    formState,
                                  }) => (
                                    <InputMask
                                      {...field}
                                      mask="9999-99-99"
                                      slotChar="yyyy-mm-dd"
                                      disabled
                                      onFocus={(e) => e.target.select()}
                                    />
                                  )}
                                />
                              </div>
                              {_.get(
                                errors,
                                'vehicleInformation.INSPT_VALID_PD_BGNDE'
                              ) ? (
                                <small className="p-error">
                                  {
                                    _.get(
                                      errors,
                                      'vehicleInformation.INSPT_VALID_PD_BGNDE'
                                    )?.message
                                  }
                                </small>
                              ) : (
                                _.get(
                                  errors,
                                  'vehicleInformation.INSPT_VALID_PD_ENDDE'
                                ) && (
                                  <small className="p-error">
                                    {
                                      _.get(
                                        errors,
                                        'vehicleInformation.INSPT_VALID_PD_ENDDE'
                                      )?.message
                                    }
                                  </small>
                                )
                              )}
                            </div>
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <Controller
                              control={control}
                              name="vehicleInformation.MTRS_FOM_NM"
                              defaultValue=""
                              render={({ field, fieldState, formState }) => (
                                <Titled.InputText
                                  title="원동기형식"
                                  {...field}
                                  readOnly
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 lg:col-3 mb-0">
                            <Controller
                              control={control}
                              name="vehicleInformation.STMD_AT"
                              defaultValue=""
                              render={({ field, fieldState, formState }) => (
                                <Titled.InputText
                                  title="튜닝이력"
                                  {...field}
                                  value={
                                    field.value === YN.NO ? '없음' : '있음'
                                  }
                                  readOnly
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 lg:col-3 mb-0">
                            <Controller
                              control={control}
                              name="vehicleInformation.PRPOS_CHANGE_SE_AT"
                              defaultValue=""
                              render={({ field, fieldState, formState }) => (
                                <Titled.InputText
                                  title="용도변경이력"
                                  {...field}
                                  value={
                                    field.value === YN.NO ? '없음' : '있음'
                                  }
                                  readOnly
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <div className="field m-0">
                              <label>차종구분</label>
                              <div className="p-inputgroup">
                                <Controller
                                  control={control}
                                  name="vehicleInformation.madeAt"
                                  defaultValue="D"
                                  render={({
                                    field,
                                    fieldState,
                                    formState,
                                  }) => (
                                    <Dropdown
                                      id={field.name}
                                      {...field}
                                      inputRef={field.ref}
                                      options={VEHICLE_TYPE_LEVEL1}
                                      onChange={({ value }) => {
                                        field.onChange(value);

                                        const detailModel =
                                          setDetailedModelType({
                                            madeAt: value,
                                          });

                                        const [
                                          ,
                                          insurancePriceVAT,
                                          insurancePrice,
                                        ] = setInsurancePrice({
                                          madeAt: value,
                                          detailModel,
                                        });
                                        calculatePrices({
                                          // checkPriceVAT,
                                          // checkPrice,
                                          insurancePriceVAT,
                                          insurancePrice,
                                        });
                                      }}
                                      disabled
                                    />
                                  )}
                                />
                                <Controller
                                  control={control}
                                  name="vehicleInformation.VHCTY_ASORT_CODE"
                                  defaultValue="1"
                                  // rules={{
                                  //   required: '필수 입력항목입니다.',
                                  // }}
                                  render={({
                                    field,
                                    fieldState,
                                    formState,
                                  }) => (
                                    <Dropdown
                                      id={field.name}
                                      {...field}
                                      inputRef={field.ref}
                                      options={
                                        baseValueItems['VHCTY_ASORT_CODE']
                                      }
                                      onChange={({ value }) => {
                                        field.onChange(value);

                                        const detailModel =
                                          setDetailedModelType({
                                            assortType: value,
                                          });
                                        const [, checkPriceVAT, checkPrice] =
                                          setCheckPrice();
                                        const [
                                          ,
                                          insurancePriceVAT,
                                          insurancePrice,
                                        ] = setInsurancePrice({
                                          assortType: value,
                                          detailModel,
                                        });
                                        calculatePrices({
                                          checkPriceVAT,
                                          checkPrice,
                                          insurancePriceVAT,
                                          insurancePrice,
                                        });
                                      }}
                                      disabled
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <Controller
                              control={control}
                              name="vehicleInformation.detailedModelType"
                              defaultValue="SA"
                              // rules={{
                              //   required: '필수 입력항목입니다.',
                              // }}
                              render={({ field, fieldState, formState }) => (
                                <Titled.Dropdown
                                  id={field.name}
                                  {..._.omit(field, ['ref'])}
                                  inputRef={field.ref}
                                  title="차종상세구분"
                                  options={detailedModelTypeOptions}
                                  onChange={(value) => {
                                    field.onChange(value);

                                    const [, checkPriceVAT, checkPrice] =
                                      setCheckPrice();
                                    const [
                                      ,
                                      insurancePriceVAT,
                                      insurancePrice,
                                    ] = setInsurancePrice({
                                      detailModel: value,
                                    });
                                    calculatePrices({
                                      checkPriceVAT,
                                      checkPrice,
                                      insurancePriceVAT,
                                      insurancePrice,
                                    });
                                  }}
                                  disabled
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <Controller
                              control={control}
                              name="vehicleInformation.GRBX_KND_CODE"
                              defaultValue=""
                              // rules={{
                              //   required: '필수 입력항목입니다.',
                              // }}
                              render={({ field, fieldState, formState }) => (
                                <Titled.Dropdown
                                  id={field.name}
                                  {...field}
                                  title="변속기"
                                  options={baseValueItems['GRBX_KND_CODE']}
                                  disabled
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <Controller
                              control={control}
                              name="vehicleInformation.USE_FUEL_CODE"
                              defaultValue=""
                              render={({ field, fieldState, formState }) => (
                                <Titled.Dropdown
                                  id={field.name}
                                  {...field}
                                  title="연료"
                                  options={baseValueItems['USE_FUEL_CODE']}
                                  onChange={(e) => {
                                    field.onChange(e);

                                    const updateVehicleInfo = {
                                      ..._.get(
                                        currentValues,
                                        'vehicleInformation'
                                      ),
                                      USE_FUEL_CODE: e,
                                    };

                                    setDetailedModelType({
                                      vehicleInformation: updateVehicleInfo,
                                    });
                                  }}
                                  disabled
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <Controller
                              control={control}
                              name="vehicleInformation.displacement"
                              defaultValue={0}
                              render={({ field, fieldState, formState }) => (
                                <Titled.InputNumber
                                  id={field.name}
                                  {...field}
                                  title="배기량"
                                  suffix="cc"
                                  selectOnFocus
                                  error={_.get(errors, field.name)}
                                  disabled
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 lg:col-6 mb-0">
                            <Controller
                              control={control}
                              name="vehicleInformation.maxPerson"
                              defaultValue={0}
                              render={({ field, fieldState, formState }) => (
                                <Titled.InputNumber
                                  id={field.name}
                                  {...field}
                                  title="승차인원"
                                  suffix="명 이하"
                                  selectOnFocus
                                  error={_.get(errors, field.name)}
                                  disabled
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Panel>
                    </BlockUI>
                  </div>
                </div>
              </Panel>
            </div>

            <div className="col-12 mb-2 lg:col-6 lg:mb-0 py-0">
              <BlockUI
                blocked={_.isEmpty(
                  _.get(currentValues, 'vehicleInformation.CNM')
                )}
              >
                <Panel header="4. 결제 정보">
                  <div className="grid">
                    <div className="col-12 mb-0">
                      <div className="field mb-0">
                        <label>보증방식</label>
                        <Controller
                          control={control}
                          name="paymentInformation.ASSRNC_TY_SE_CODE"
                          defaultValue="2"
                          render={({ field, fieldState, formState }) => (
                            <div className="bg-transparent flex flex-auto flex-wrap align-items-center justify-content-start gap-3">
                              {baseValueItems['ASSRNC_TY_SE_CODE'].map(
                                (item, idx) => (
                                  <div key={`${field.name}_${idx}`}>
                                    <div className="flex flex-auto align-items-center justify-content-start gap-1">
                                      <RadioButton
                                        inputId={`trb_${field.name}_${idx}`}
                                        name={field.name}
                                        value={item.value}
                                        onChange={(e) => {
                                          field.onChange(e);

                                          const [, checkPriceVAT, checkPrice] =
                                            setCheckPrice('RADIO');
                                          const [
                                            ,
                                            insurancePriceVAT,
                                            insurancePrice,
                                          ] = setInsurancePrice('RADIO');
                                          calculatePrices({
                                            checkPriceVAT,
                                            checkPrice,
                                            insurancePriceVAT,
                                            insurancePrice,
                                          });

                                          if (e.value === '2') {
                                            setValue(
                                              'paymentInformation.insurancePricePaidYN',
                                              'N'
                                            );
                                          } else {
                                            setValue(
                                              'paymentInformation.insurancePricePaidYN',
                                              'Y'
                                            );
                                            //* 보험료 결제시점, 결제여부 업데이트
                                            clearInsurancePrice('RADIO');
                                          }
                                        }}
                                        checked={field.value === item.value}
                                        disabled
                                      />
                                      <label
                                        className={item.labelColor}
                                        htmlFor={`trb_${field.name}_${idx}`}
                                      >
                                        {item.label}
                                      </label>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 lg:col-5 mb-0">
                      <Controller
                        control={control}
                        name="paymentInformation.ICNY_CODE"
                        defaultValue=""
                        rules={{
                          validate: (v) =>
                            _.get(
                              currentValues,
                              'paymentInformation.ASSRNC_TY_SE_CODE'
                            ) === '2'
                              ? v !== ''
                              : true,
                        }}
                        render={({ field, fieldState, formState }) => (
                          <Titled.Dropdown
                            id={field.name}
                            {...field}
                            title="보증업체"
                            options={codes.insurances}
                            disabled
                            placeholder="없음"
                            onChange={(value) => {
                              field.onChange(value);

                              const [, checkPriceVAT, checkPrice] =
                                setCheckPrice();
                              const [, insurancePriceVAT, insurancePrice] =
                                setInsurancePrice({
                                  warrantyType: value,
                                });
                              calculatePrices({
                                checkPriceVAT,
                                checkPrice,
                                insurancePriceVAT,
                                insurancePrice,
                              });
                            }}
                            error={_.get(errors, field.name)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-7 mb-0">
                      <div className="field m-0">
                        <label>보험료</label>
                        <div className="flex flex-row align-content-center justify-content-evenly gap-1">
                          <Controller
                            control={control}
                            name="paymentInformation.totalInsurancePrice"
                            defaultValue={0}
                            render={({ field, fieldState, formState }) => (
                              <InputNumber
                                id={field.name}
                                {...field}
                                className="w-full"
                                inputClassName={classNames(
                                  'w-full text-right',
                                  {
                                    'bg-yellow-200': !isInsurancePricePaid(),
                                    'bg-green-200': isInsurancePricePaid(),
                                  }
                                )}
                                tooltip="기존 성능점검에서 추가된 보험료만 표기됩니다."
                                tooltipOptions={{ position: 'top' }}
                                readOnly
                                suffix=" 원"
                              />
                            )}
                          />
                          <Controller
                            control={control}
                            name="paymentInformation.insurancePricePaidBy"
                            defaultValue={''}
                            render={({ field, fieldState, formState }) => (
                              <Dropdown
                                className="w-full"
                                {...field}
                                options={PAYMENT_METHOD}
                                onChange={({ value }) => {
                                  field.onChange(value);
                                }}
                                disabled
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <Controller
                        control={control}
                        name="paymentInformation.originTotalInsurancePrice"
                        defaultValue={0}
                        render={({ field }) => (
                          <Titled.InputNumber
                            id={field.name}
                            title="기존 보험료"
                            {...field}
                            readOnly
                            suffix=" 원"
                          />
                        )}
                      />
                    </div>

                    <div className="col-12 mb-1 lg:col-5 lg:mb-0">
                      <Controller
                        control={control}
                        name="paymentInformation.checkPriceItemId"
                        defaultValue=""
                        render={({ field, fieldState, formState }) => (
                          <Titled.Dropdown
                            id={field.name}
                            {...field}
                            title="점검료구분"
                            options={_.get(
                              codes,
                              'checkPriceTable.checkPriceItems'
                            )}
                            placeholder="없음"
                            optionLabel="title"
                            optionValue="id"
                            onChange={(value) => {
                              field.onChange(value);

                              const [, checkPriceVAT, checkPrice] =
                                setCheckPrice({ selectedItemId: value });
                              calculatePrices({
                                checkPriceVAT,
                                checkPrice,
                              });
                            }}
                            error={_.get(errors, field.name)}
                            disabled
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-7 mb-0">
                      <div className="field m-0">
                        <label>점검료</label>
                        <div className="flex flex-row gap-1">
                          <Controller
                            control={control}
                            name="paymentInformation.totalCheckPrice"
                            defaultValue={0}
                            render={({ field, fieldState, formState }) => (
                              <InputNumber
                                id={field.name}
                                {...field}
                                className="w-full"
                                inputClassName={classNames(
                                  'w-full text-right',
                                  {
                                    'bg-yellow-200': !isCheckPricePaid(),
                                    'bg-green-200': isCheckPricePaid(),
                                  }
                                )}
                                readOnly
                                suffix=" 원"
                              />
                            )}
                          />
                          <Controller
                            control={control}
                            name="paymentInformation.checkPricePaidBy"
                            defaultValue={''}
                            render={({ field, fieldState, formState }) => (
                              <Dropdown
                                className="w-full"
                                {...field}
                                options={PAYMENT_METHOD}
                                onChange={({ value }) => {
                                  field.onChange(value);
                                }}
                                disabled
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 lg:col-4 mb-0">
                      <Controller
                        control={control}
                        name="paymentInformation.summaryPrice"
                        defaultValue={0}
                        render={({ field, fieldState, formState }) => (
                          <Titled.InputNumber
                            id={field.name}
                            {...field}
                            title="합계"
                            suffix="원"
                            readOnly
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4 mb-0">
                      <Controller
                        control={control}
                        name="paymentInformation.vat"
                        defaultValue={0}
                        render={({ field, fieldState, formState }) => (
                          <Titled.InputNumber
                            id={field.name}
                            {...field}
                            title="VAT"
                            suffix="원"
                            readOnly
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4 mb-0">
                      <div className="field m-0">
                        <label>점검료할인금액</label>
                        <div className="p-inputgroup">
                          <Controller
                            control={control}
                            name="paymentInformation.discountedCheckPrice"
                            defaultValue={0}
                            rules={{
                              min: {
                                value: 0,
                                message: '0보다 커야 합니다.',
                              },
                              max: {
                                value: _.get(
                                  currentValues,
                                  'paymentInformation.totalCheckPrice'
                                ),
                                message: '할인 금액이 점검료를 초과합니다.',
                              },
                            }}
                            render={({ field, fieldState, formState }) => (
                              <InputNumber
                                id={field.name}
                                inputClassName="text-right"
                                onChange={(e) => {
                                  field.onChange(e.value);

                                  setCheckPrice({
                                    discountedCheckPrice: e.value,
                                  });
                                  calculatePrices({
                                    discountedCheckPrice: e.value,
                                  });
                                }}
                                onBlur={(e) => {
                                  const value = Number(
                                    e.target.value.replace(/[^0-9]/g, '')
                                  );
                                  field.onChange(value);

                                  setCheckPrice({
                                    discountedCheckPrice: value,
                                  });
                                  calculatePrices({
                                    discountedCheckPrice: value,
                                  });
                                }}
                                suffix=" 원"
                                min={0}
                                max={_.get(
                                  currentValues,
                                  'paymentInformation.totalCheckPrice'
                                )}
                                inputmode="numeric"
                                onFocus={(e) => e.target.select()}
                                disabled
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mb-0">
                      <Controller
                        control={control}
                        name="paymentInformation.discountReason"
                        defaultValue=""
                        rules={{
                          required:
                            _.get(
                              currentValues,
                              'paymentInformation.discountedCheckPrice'
                            ) > 0 && '필수 입력항목입니다.',
                        }}
                        render={({ field, fieldState, formState }) => (
                          <Titled.InputText
                            id={field.name}
                            {...field}
                            title="할인사유"
                            disabled
                          />
                        )}
                      />
                    </div>

                    <div className="col-12 mb-0">
                      <div className="field m-0">
                        <Controller
                          control={control}
                          name={'paymentInformation.totalPrice'}
                          defaultValue={0}
                          // rules={{
                          //   required: '필수 입력항목입니다.',
                          // }}
                          render={({ field, fieldState, formState }) => (
                            <Titled.InputNumber
                              id={field.name}
                              {...field}
                              title={'결제금액(VAT 포함)'}
                              className={classNames('font-bold', {
                                'bg-yellow-200': !(
                                  isCheckPricePaid() && isInsurancePricePaid()
                                ),
                                'bg-green-200':
                                  isCheckPricePaid() && isInsurancePricePaid(),
                              })}
                              readOnly
                              suffix=" 원"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 lg:col-6 mb-0">
                      <Controller
                        control={control}
                        name="paymentInformation.paidPrice"
                        defaultValue={0}
                        // rules={{
                        //   required: '필수 입력항목입니다.',
                        // }}
                        render={({ field, fieldState, formState }) => (
                          <Titled.InputNumber
                            id={field.name}
                            {...field}
                            title="기결제금액"
                            suffix="원"
                            readOnly
                            className={classNames('text-right', {
                              'font-bold bg-green-200':
                                isCheckPricePaid() && isInsurancePricePaid(),
                            })}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-6 mb-0">
                      <Controller
                        control={control}
                        name="paymentInformation.unpaidPrice"
                        defaultValue={0}
                        // rules={{
                        //   required: '필수 입력항목입니다.',
                        // }}
                        render={({ field, fieldState, formState }) => (
                          <Titled.InputNumber
                            id={field.name}
                            {...field}
                            title="미결제잔액"
                            suffix="원"
                            readOnly
                            className={classNames('text-right', {
                              'font-bold bg-yellow-200': !(
                                isCheckPricePaid() && isInsurancePricePaid()
                              ),
                            })}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Panel>
              </BlockUI>

              <Panel header="비고" className="mt-2">
                <Controller
                  control={control}
                  name="note"
                  defaultValue=""
                  render={({ field }) => (
                    <InputTextarea
                      id={field.name}
                      className="w-full"
                      {...field}
                      rows={4}
                      autoResize
                    />
                  )}
                />
              </Panel>
            </div>
          </div>
        </form>

        {isDialogOpened.customerCompany && (
          <CustomerCompanyDialog
            visible
            quickClose={true}
            onHide={async (newCustomerCompany) => {
              if (newCustomerCompany) {
                const { customerCompanyId: newCustomerCompanyId } =
                  newCustomerCompany;
                const [customerCompanies, customerCompanyId, customerCompany] =
                  await getCustomerCompanyCodes(newCustomerCompanyId);
                const [customers, , customer] = await getCustomerCodes(
                  customerCompanyId
                );

                setCodes((ps) => ({ ...ps, customerCompanies, customers }));
                setValue('customerCompany', customerCompany);
                setValue('customer', customer);
              }

              setIsDialogOpened((ps) => ({ ...ps, customerCompany: false }));
            }}
          />
        )}

        {isDialogOpened.customer && (
          <CustomerDialog
            visible
            quickClose={true}
            onHide={async (newCustomer) => {
              if (newCustomer) {
                const { customerCompanyId: newCCId, customerId: newCId } =
                  newCustomer;
                const [customerCompanies, , customerCompany] =
                  await getCustomerCompanyCodes(newCCId);
                const [customers, , customer] = await getCustomerCodes(
                  newCCId,
                  newCId
                );

                setCodes((ps) => ({ ...ps, customerCompanies, customers }));
                setValue('customerCompany', customerCompany);
                setValue('customer', customer);
              }

              setIsDialogOpened((ps) => ({ ...ps, customer: false }));
            }}
          />
        )}

        {isDialogOpened.userSearch && (
          <SearchDialog.User
            userInfo={userInfo}
            preSelected={{
              associationId: _.get(currentValues, 'inspector.associationId'),
              enterpriseId: _.get(currentValues, 'inspector.enterpriseId'),
              shopId: _.get(currentValues, 'inspector.shopId'),
            }}
            onHide={async (userData) => {
              setIsDialogOpened((ps) => ({ ...ps, userSearch: false }));

              if (userData) {
                const currentInspector = _.get(currentValues, 'inspector');
                const [{ association, enterprise, shop }, inspector] =
                  separateUserData(userData);
                const [
                  [checkPriceTable],
                  [insurancePriceTables, insuranceCompany, , insurances],
                ] = await Promise.all([
                  getCheckPriceTable({
                    associationId: _.get(association, 'associationId'),
                    enterpriseId: _.get(enterprise, 'enterpriseId'),
                    shopId: _.get(shop, 'shopId'),
                  }),
                  getInsurancePriceTables({
                    associationId: _.get(association, 'associationId'),
                    enterpriseId: _.get(enterprise, 'enterpriseId'),
                    shopId: _.get(shop, 'shopId'),
                  }),
                ]);

                setCodes((ps) => ({
                  ...ps,
                  insurancePriceTables,
                  insurances,
                  checkPriceTable,
                }));

                setValue('association', association);
                setValue('enterprise', enterprise);
                setValue('shop', shop);
                setValue('inspector', inspector);

                setValue('paymentInformation.ICNY_CODE', insuranceCompany);

                if (
                  _.get(currentInspector, 'shopId') !==
                  _.get(inspector, 'shopId')
                ) {
                  setValue('customerCompany', {});
                  setValue('customer', {});
                }

                setInsurancePrice({ insurancePriceTables });
                setCheckPrice({ checkPriceTable });
                calculatePrices();
              }
            }}
          />
        )}

        {isDialogOpened.customerSearch && (
          <SearchDialog.Customer
            currentInspector={_.get(currentValues, 'inspector')}
            onHide={(customerData) => {
              setIsDialogOpened((ps) => ({ ...ps, customerSearch: false }));

              if (customerData) {
                const [customerCompany, customer] =
                  separateCustomerData(customerData);
                setValue('customerCompany', customerCompany);
                setValue('customer', customer);
              }
            }}
          />
        )}
      </BlockUI>
    </Dialog>
  );
};

export default RecheckBaseDataDialog;
