import _ from 'lodash';
import UserRoleType from '../enums/UserRoleType';

export const MyInfoUtil = {
  unpack(myInfo) {
    return {
      myAssociationId: _.get(myInfo, 'associationInfo.associationId'),
      myAssociationName: _.get(myInfo, 'associationInfo.associationName'),
      myEnterpriseId: _.get(myInfo, 'enterpriseInfo.enterpriseId'),
      myEnterpriseName: _.get(myInfo, 'enterpriseInfo.enterpriseName'),
      myShopId: _.get(myInfo, 'shopInfo.shopId'),
      myShopName: _.get(myInfo, 'shopInfo.shopName'),
      myUserId: _.get(myInfo, 'userId'),
      myRoleCode: _.get(myInfo, 'roleCode'),
      myUserPosition: _.get(myInfo, 'userPosition'),
      myName: _.get(myInfo, 'userName'),
    };
  },
  getAssociationId(myInfo) {
    return _.get(myInfo, 'associationInfo.associationId');
  },
  getAssociationName(myInfo) {
    return _.get(myInfo, 'associationInfo.associationName');
  },
  getEnterpriseId(myInfo) {
    return _.get(myInfo, 'enterpriseInfo.enterpriseId');
  },
  getEnterpriseName(myInfo) {
    return _.get(myInfo, 'enterpriseInfo.enterpriseName');
  },
  getShopId(myInfo) {
    return _.get(myInfo, 'shopInfo.shopId');
  },
  getShopName(myInfo) {
    return _.get(myInfo, 'shopInfo.shopName');
  },
  getUserId(myInfo) {
    return _.get(myInfo, 'userId');
  },
  getRoleCode(myInfo) {
    return _.get(myInfo, 'roleCode');
  },
  getUserPosition(myInfo) {
    return _.get(myInfo, 'userPosition');
  },
  isInspector(roleCode) {
    return UserRoleType.S_INS.value === roleCode;
  },
};
