import React, { useEffect, useMemo, useState } from 'react';

import { Button } from 'primereact/button';
import { COUNT_PER_PAGE } from './../../../constants/Constants';
import { CodeUtil } from '../../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { ProgressBar } from 'primereact/progressbar';
import { ServiceProvider } from '../../../services';
import UserPosition from './../../../enums/UserPosition';
import _ from 'lodash';
import { myInfoSelector } from '../../../recoil/selectors';
import { useRecoilValueLoadable } from 'recoil';

//* 전체 데이터 조회하게 되는 query
const DEFAULT_SEARCH_CONDITIONS = {
  associationId: '',
  enterpriseId: '',
  shopId: '',
  searchText: '',
};
const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
};

const searchService = ServiceProvider.search;

export const UserSearchDialog = ({ userInfo, preSelected, onHide }) => {
  const { myAssociationId, myEnterpriseId, myRoleCode, myUserPosition } =
    useMemo(() => MyInfoUtil.unpack(userInfo), [userInfo]);
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);

  const [loading, setLoading] = useState(false);
  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [codes, setCodes] = useState({
    associations: [],
    enterprises: [],
    shops: [],
  });

  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });
  const [data, setData] = useState([]);

  const getUsers = async (conditions, page, size) => {
    const { associationId, enterpriseId, shopId, searchText } = conditions;

    setLoading(true);

    const params = {
      sto: 'shop',
      aid: associationId,
      eid: enterpriseId,
      sid: shopId,
      st: searchText,
      page: page + 1,
      size: size,
    };

    try {
      const { data } = await searchService.users(params);

      setTotalRecords(data.total);
      setData(data.list);
    } catch (error) {
      window.cerp.dialog.error(
        '조회실패',
        `[${error?.code}] ${error?.message}`
      );
    }
    setLoading(false);
  };

  const onInit = () => {
    setData([]);
  };

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const myInfo = MyInfoUtil.unpack(userInfo);
        const [associations, associationId] =
          await CodeUtil.getAssociationCodes(myInfo, {
            preSelectedAssociationId: myAssociationId,
          });
        const [enterprises, enterpriseId] = await CodeUtil.getEnterpriseCodes(
          myInfo,
          {
            isParentEmpty: _.isEmpty(associations),
            associationId: myAssociationId,
          }
        );
        const [shops, shopId] = await CodeUtil.getShopCodes(myInfo, {
          isParentEmpty: _.isEmpty(enterprises),
          enterpriseId: myEnterpriseId,
        });

        await getUsers(
          { ...searchConditions, associationId, enterpriseId, shopId },
          _.get(DEFAULT_LAZY_PARAMS, 'page'),
          _.get(DEFAULT_LAZY_PARAMS, 'rows')
        );

        setCodes((ps) => ({ ...ps, associations, enterprises, shops }));

        setSearchConditions((ps) => ({
          ...ps,
          associationId,
          enterpriseId,
          shopId,
        }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.state]);

  return (
    <>
      {(() => {
        switch (myInfoLoadable.state) {
          case 'hasValue':
            const myInfo = MyInfoUtil.unpack(userInfo);
            return (
              <Dialog
                visible
                modal
                header="사용자 검색"
                style={{ width: '72vw' }}
                breakpoints={{ '960px': '84vw', '640px': '96vw' }}
                onHide={onHide}
              >
                <div className="grid mb-0">
                  <div className="col-12 sm:col-6 lg:col-3">
                    <div className="field mb-0">
                      <label>소속협회</label>
                      <Dropdown
                        className="w-full"
                        value={_.get(searchConditions, 'associationId')}
                        options={codes.associations}
                        onChange={async ({ value }) => {
                          const [enterprises, enterpriseId] =
                            await CodeUtil.getEnterpriseCodes(myInfo, {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'associations')
                              ),
                              associationId: value,
                            });
                          const [shops, shopId] = await CodeUtil.getShopCodes(
                            myInfo,
                            {
                              isParentEmpty: _.isEmpty(enterprises),
                            }
                          );
                          onInit();
                          setCodes((ps) => ({ ...ps, enterprises, shops }));
                          setSearchConditions((ps) => ({
                            ...ps,
                            associationId: value,
                            enterpriseId,
                            shopId,
                          }));
                        }}
                        disabled={
                          !(
                            _.includes([UserPosition.Erp], myUserPosition) ||
                            MyInfoUtil.isInspector(myRoleCode)
                          ) || preSelected?.associationId !== undefined
                        }
                        filter
                        placeholder={
                          loading ? (
                            <>
                              <i className="pi pi-spin pi-spinner m-0 mr-2" />
                              조회하고 있습니다...
                            </>
                          ) : (
                            '협회가 없습니다.'
                          )
                        }
                        emptyMessage="선택 가능한 옵션 없음"
                      />
                    </div>
                  </div>
                  <div className="col-12 sm:col-6 lg:col-3">
                    <div className="field mb-0">
                      <label>소속점검법인</label>
                      <Dropdown
                        className="w-full"
                        value={_.get(searchConditions, 'enterpriseId')}
                        options={codes.enterprises}
                        onChange={async ({ value }) => {
                          const [shops, shopId] = await CodeUtil.getShopCodes(
                            myInfo,
                            {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'enterprises')
                              ),
                              enterpriseId: value,
                            }
                          );
                          onInit();
                          setCodes((ps) => ({ ...ps, shops }));
                          setSearchConditions((ps) => ({
                            ...ps,
                            enterpriseId: value,
                            shopId,
                          }));
                        }}
                        disabled={
                          !(
                            _.includes(
                              [UserPosition.Erp, UserPosition.Association],
                              myUserPosition
                            ) || MyInfoUtil.isInspector(myRoleCode)
                          ) || preSelected?.enterpriseId !== undefined
                        }
                        filter
                        placeholder={
                          loading ? (
                            <>
                              <i className="pi pi-spin pi-spinner m-0 mr-2" />
                              조회하고 있습니다...
                            </>
                          ) : (
                            '점검법인이 없습니다.'
                          )
                        }
                        emptyMessage="선택 가능한 옵션 없음"
                      />
                    </div>
                  </div>
                  <div className="col-12 sm:col-6 lg:col-3">
                    <div className="field mb-0">
                      <label>소속점검장</label>
                      <Dropdown
                        className="w-full"
                        value={_.get(searchConditions, 'shopId')}
                        options={codes.shops}
                        onChange={({ value }) => {
                          onInit();
                          setSearchConditions((ps) => ({
                            ...ps,
                            shopId: value,
                          }));
                        }}
                        disabled={
                          !(
                            _.includes(
                              [
                                UserPosition.Erp,
                                UserPosition.Association,
                                UserPosition.Enterprise,
                              ],
                              myUserPosition
                            ) || MyInfoUtil.isInspector(myRoleCode)
                          ) || preSelected?.shopId !== undefined
                        }
                        filter
                        placeholder={
                          loading ? (
                            <>
                              <i className="pi pi-spin pi-spinner m-0 mr-2" />
                              조회하고 있습니다...
                            </>
                          ) : (
                            '점검장이 없습니다.'
                          )
                        }
                        emptyMessage="선택 가능한 옵션 없음"
                      />
                    </div>
                  </div>
                  <div className="col-12 sm:col-6 lg:col-3">
                    <div className="field mb-0">
                      <label>검색키워드</label>
                      <div className="p-inputgroup">
                        <InputText
                          autoFocus
                          value={searchConditions.searchText}
                          onChange={(e) =>
                            setSearchConditions((ps) => ({
                              ...ps,
                              searchText: e.target.value,
                            }))
                          }
                          onKeyDown={async (e) => {
                            if (e.key === 'Enter') {
                              await getUsers(
                                searchConditions,
                                _.get(DEFAULT_LAZY_PARAMS, 'page'),
                                _.get(DEFAULT_LAZY_PARAMS, 'rows')
                              );
                            }
                          }}
                        />
                        <Button
                          icon="pi pi-search"
                          onClick={async () =>
                            await getUsers(
                              searchConditions,
                              _.get(DEFAULT_LAZY_PARAMS, 'page'),
                              _.get(DEFAULT_LAZY_PARAMS, 'rows')
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <DataTable
                  size="small"
                  loading={loading}
                  value={data}
                  lazy
                  rows={lazyParams.rows}
                  first={lazyParams.first}
                  totalRecords={totalRecords}
                  paginator
                  responsiveLayout="scroll"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                  rowsPerPageOptions={COUNT_PER_PAGE}
                  onPage={async (event) => {
                    setLazyParams(event);
                    await getUsers(searchConditions, event.page, event.rows);
                  }}
                  resizableColumns
                  showGridlines
                  selectionPageOnly={true}
                  emptyMessage="데이터가 없습니다."
                  rowHover
                  rowClassName="cursor-pointer"
                  onRowDoubleClick={({ data }) => onHide(data)}
                >
                  <Column field="associationName" header="협회" />
                  <Column field="enterpriseName" header="점검업체" />
                  <Column field="shopName" header="점검장" />
                  <Column field="userName" header="사용자" />
                </DataTable>
              </Dialog>
            );
          case 'loading':
            return <ProgressBar mode="indeterminate" />;
          case 'hasError':
            return null;
          default:
        }
      })()}
    </>
  );
};
